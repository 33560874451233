import { useEffect } from 'react';

import { withAuthn } from '@/utilities/auth/withAuthn';
import { pagesPath } from '@/lib/$path';
import { useRouter } from 'next/router';

const Index: React.VFC = () => {
  const router = useRouter();

  useEffect(() => {
    router.push(pagesPath.products.$url());
  }, [router]);

  return null;
};

export default withAuthn(Index);
