import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loader } from '@/components/atoms/Loader';

// NOTE: ログイン済みの場合も表示される可能性があるため、
// ローディング相当の表記に留めるのが良い
const Redirecting = (): JSX.Element => <Loader />;

export const withAuthn: typeof withAuthenticationRequired = (component) =>
  withAuthenticationRequired(component, {
    onRedirecting: Redirecting,
  });
